<template>
  <div class="page">
    <div
      v-if="routeFrom && (routeFrom.name === 'Accounts')"
      class="back-link"
      @click="$router.push(routeFrom)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('words.accountsManagement') }}
      </span>
    </div>
    <div
      v-else
      class="back-link"
      @click="$router.push({ name: 'Organisations' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('organisationDetail.organisationsList') }}
      </span>
    </div>

    <b-container
      fluid
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
      />
      <h2>{{ $t('addOrganisation.title') }}</h2>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
            style="overflow-x: hidden;"
          >
            <form>
              <h3 class="line-header">
                <img
                  src="@/assets/icons/company.svg"
                  alt="Icon company"
                > {{ $t('addOrganisation.description') }}
              </h3>

              <!-- ADDITIONNAL_ID -->
              <div
                v-if="isFieldVisible('additional_id', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-6">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="additional_id"
                    :rules="isFieldRequired('additional_id', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('additional_id', formConfig.requiredFields)">
                        {{ $t('forms.organisation.idComp.label') }}
                      </label>
                      <p class="label-help">
                        {{ $t('forms.organisation.idComp.help') }}
                      </p>
                      <input
                        v-model="form.additional_id"
                        v-sanitize
                        class="form-control"
                        type="text"
                        :placeholder="$t('forms.organisation.idComp.label')"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- DISPLAY_NAME -->
              <div
                v-if="isFieldVisible('display_name', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-12">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="display_name"
                    :rules="isFieldRequired('display_name', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('display_name', formConfig.requiredFields)">
                        {{ $t('forms.organisation.name.label') }}
                      </label>
                      <p class="label-help">
                        {{ $t('forms.organisation.name.help') }}
                      </p>
                      <input
                        v-model="form.display_name"
                        v-sanitize
                        class="form-control"
                        type="text"
                        :placeholder="$t('forms.organisation.name.label')"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form-row">
                <!-- ACRONYM -->
                <div
                  v-if="isFieldVisible('acronym', formConfig.hiddenFields)"
                  class="form-group col-6"
                >
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="acronym"
                    :rules="isFieldRequired('acronym', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('acronym', formConfig.requiredFields)">
                        {{ $t('forms.organisation.acronym') }}
                      </label>
                      <input
                        v-model="form.acronym"
                        v-sanitize
                        class="form-control"
                        type="text"
                        :placeholder="$t('forms.organisation.acronym')"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>

                <!-- REGISTRATION NUMBER -->
                <div
                  v-if="isFieldVisible('registration_number', formConfig.hiddenFields)"
                  class="form-group col-6"
                >
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="registration_number"
                    :rules="isFieldRequired('registration_number', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('registration_number', formConfig.requiredFields)">
                        {{ $t('forms.organisation.siret') }}
                      </label>
                      <input
                        v-model="form.registration_number"
                        v-sanitize
                        class="form-control"
                        type="text"
                        :placeholder="$t('forms.organisation.siret')"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- ORGANISATION TYPE -->
              <div
                v-if="isFieldVisible('display_name', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-6">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="organisation_type"
                    :rules="isFieldRequired('organisation_type', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label
                        :class="isFieldRequired('organisation_type', formConfig.requiredFields)"
                      >
                        {{ $t('forms.organisation.type') }}
                      </label>
                      <Multiselect
                        v-model="form.organisation_type"
                        :options="typesOrganisations"
                        track-by="choice"
                        label="label"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        :searchable="false"
                        :placeholder="$t('forms.organisation.type')"
                      />
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- WEBSITE -->
              <div
                v-if="isFieldVisible('website_url', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-9">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="website_url"
                    :rules="isFieldRequired('website', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('website', formConfig.requiredFields)">
                        {{ $t('forms.organisation.website') }}
                      </label>
                      <input
                        v-model="form.website_url"
                        v-sanitize
                        class="form-control"
                        type="text"
                        placeholder="Site web de l'organisation"
                        @focus="form.website_url === null ? form.website_url = 'https://' : null"
                        @blur="form.website_url === 'https://' ? form.website_url = null : null"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- DESCRIPTION -->
              <div
                v-if="isFieldVisible('description', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-12">
                  <ValidationProvider
                    ref="description"
                    v-slot="{ classes, errors }"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('description', formConfig.requiredFields)">
                        {{ $t('forms.organisation.description') }}
                      </label>
                      <textarea
                        v-model="form.description"
                        v-sanitize
                        class="form-control"
                      />
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>


              <h3 class="line-header">
                <img
                  src="@/assets/icons/mail.svg"
                  alt="Icon mail"
                > {{ $t('addOrganisation.contact') }}
              </h3>

              <!-- PHONE NUMBER -->
              <div
                v-if="isFieldVisible('phone_number', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-6">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="phone_number"
                    :rules="isFieldRequired('phone_number', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('phone_number', formConfig.requiredFields)">
                        {{ $t('forms.organisation.phone') }}
                      </label>
                      <input
                        v-model="form.phone_number"
                        v-sanitize
                        class="form-control"
                        type="text"
                        placeholder="Téléphone"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- POSTAL ADDRESS -->
              <div
                v-if="isFieldVisible('postal_address', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-12">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    ref="postal_address"
                    :rules="isFieldRequired('postal_address', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('postal_address', formConfig.requiredFields)">
                        {{ $t('forms.organisation.address') }}
                      </label>
                      <textarea
                        v-model="form.postal_address"
                        v-sanitize
                        class="form-control"
                        type="text"
                        placeholder="Adresse postale"
                        rows="2"
                      />
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- JURISDICTION -->
              <h3
                v-if="isFieldVisible('jurisdiction', formConfig.hiddenFields)"
                class="line-header"
              >
                <img
                  src="@/assets/icons/territory_map_cadastre.svg"
                  alt="Icon territory map cadastre"
                > {{ $t('addOrganisation.territory') }}
              </h3>
              <div
                v-if="isFieldVisible('jurisdiction', formConfig.hiddenFields)"
                class="form-row"
              >
                <div
                  class="form-check"
                  style="margin-left: -1em;"
                >
                  <input
                    v-model="territoireCheckbox"
                    class="form-check-input-table"
                    type="checkbox"
                  >
                  <label class="form-check-label">
                    {{ $t('addOrganisation.territoryCheckboxLabel') }}
                  </label>
                </div>
              </div>
              <div
                v-if="isFieldVisible('jurisdiction', formConfig.hiddenFields)"
                class="form-row"
                :class="{ disabled: territoireCheckbox }"
              >
                <div class="form-group col-8">
                  <label>
                    {{ $t('forms.organisation.territory.label') }}
                  </label>
                  <Multiselect
                    v-model="form.jurisdiction"
                    :options="organisationsJuridictions"
                    track-by="id"
                    label="display_name"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :searchable="false"
                    :preserve-search="true"
                    :show-no-results="false"
                    :placeholder="$t('forms.organisation.territory.placeholder')"
                  />
                </div>
              </div>

              <!-- CONVENTION -->
              <h3
                v-if="
                  isFieldVisible('agreement_signed', formConfig.hiddenFields) ||
                    isFieldVisible('agreement', formConfig.hiddenFields)
                "
                class="line-header"
              >
                <img
                  src="@/assets/icons/official_document_convention.svg"
                  alt="Icon official document convention"
                > {{ $t('addOrganisation.convention') }}
              </h3>
              <div
                v-if="isFieldVisible('agreement_signed', formConfig.hiddenFields)"
                class="form-row"
              >
                <div
                  class="form-check"
                  style="margin-left: -1em;"
                >
                  <input
                    v-model="form.agreement_signed"
                    class="form-check-input-table"
                    type="checkbox"
                  >
                  <label class="form-check-label">
                    {{ $t('addOrganisation.conventionCheckboxLabel') }}
                  </label>
                </div>
              </div>
              <div
                v-if="isFieldVisible('agreement', formConfig.hiddenFields)"
                class="form-row"
              >
                <div
                  class="form-group col-8"
                  style="margin-left: 1em;"
                >
                  <label>
                    {{ $t('forms.organisation.convention.label') }}
                  </label>
                  <ImportFile
                    name="agreement"
                    :type="'convention'"
                    :title="null"
                    :file-name="null"
                    accept="application/*"
                    @update="setAgreement"
                  />
                </div>
              </div>

              <h3 class="line-header">
                <img
                  src="@/assets/icons/sphere_planet.svg"
                  alt="Icon sphere planet"
                > {{ $t('addOrganisation.organisationsGroup') }}
              </h3>
              <div style="margin-left: 1em;">
                <label>
                  {{ $t('forms.organisation.organisationsGroup.label') }}
                </label>
                <p class="label-help">
                  {{ $t('forms.organisation.organisationsGroup.help') }}
                </p>
                <SearchUsergroups
                  style="width: 60%;"
                  :placeholder="$t('searchbars.usergroupPlaceholder')"
                  :types="['group-of-organisation']"
                  :limit="100"
                  @select="addOrgToSphere"
                />
                <div
                  v-if="selectedSpheres.length > 0"
                  id="orga-spheres-container"
                >
                  <div
                    v-for="selectedSphere of selectedSpheres"
                    :key="selectedSphere.id"
                    class="orga-sphere"
                  >
                    {{ selectedSphere.display_name }}
                    <b-icon-x
                      font-scale="1.5"
                      @click="removeOrgFromSphere"
                    />
                  </div>
                </div>
              </div>

              <!-- PARTNER -->
              <h3 class="line-header">
                <img
                  src="@/assets/icons/territory_map_cadastre.svg"
                  alt="Icon territory map cadastre"
                > {{ $t('addOrganisation.partnership') }}
              </h3>
              <div class="form-row">
                <div class="form-check">
                  <input
                    v-model="form.partner"
                    class="form-check-input-table"
                    type="checkbox"
                  >
                  <label class="form-check-label">
                    {{ $t('addOrganisation.partnershipCheckboxLabel') }}
                  </label>
                </div>
              </div>
            </form>
          </b-col>
          <b-col
            cols="4"
          >
            <div class="card-footer">
              <UsergroupThumbnail
                :key="`organisation-card-${cardKey}`"
                :organisation="form"
                @set="setThumbnail"
              />
              <div class="side-footer">
                <!-- <b-button
                  variant="outline-secondary"
                  @click="$router.go(-1)"
                >
                  {{ $t('buttons.cancel') }}
                </b-button> -->
                <b-button
                  variant="primary"
                  @click="handleSubmit(addOrganisation)"
                >
                  {{ $t('buttons.save') }}
                </b-button>
                <b-button
                  variant="primary"
                  @click="handleSubmit(addOrganisationAndContinue)"
                >
                  {{ $t('buttons.saveContinue') }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-container>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ImportFile from '@/components/ImportFile.vue';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';
import UsergroupThumbnail from '@/components/Usergroup/UsergroupThumbnail.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

import { slugify } from '@/utils';

import { ValidationObserver, ValidationProvider, extend, configure } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';


extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
extend('email', {
  ...email,
  message: 'Veuillez entrer une adresse email valide'
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

const LABEL_ID_COMP = process.env.VUE_APP_LABEL_ID_COMP;

export default {
  name: 'AddOrganisation',

  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider,
    ImportFile,
    SearchUsergroups,
    UsergroupThumbnail
  },

  data() {
    return {
      routeFrom: null,
      failure: false,
      loading: false,
      disabled: true,
      cardKey: 1,
      territoireCheckbox: true,
      form: {
        additional_id: null,
        display_name: null,
        acronym: null,
        registration_number: null,
        organisation_type: null,
        website_url: null,
        phone_number: null,
        postal_address: null,
        description: null,
        thumbnail: null,
        jurisdiction: null,
        agreement_signed: false,
        agreement: null,
        partner: false
      },
      selectedSpheres: [],
      labelIdComp: LABEL_ID_COMP
    };
  },

  computed: {
    ...mapState('organisations', [
      'organisationsTypes',
      'organisationsError',
      'lastCreatedOrganisation',
      'organisationsJuridictions'
    ]),
    ...mapState('form', [
      'currentFormId',
      'currentForm'
    ]),
    ...mapState('usergroups', [
      'currentUsergroup'
    ]),

    formConfig() {
      return this.$config.forms.organisation;
    },

    typesOrganisations() {
      return this.organisationsTypes.map((el, i) => {
        return {
          choice: i,
          label: el.display_name
        };
      });
    }
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.SET_CURRENT_FORM({
          id: 'addOrganisation',
          form: newValue
        });
      }
    },
    organisationsError(newValue) {
      if (newValue) {
        for (const [key, value] of Object.entries(newValue)) {
          this.$refs[key].applyResult({
            errors: value,
            valid: false,
            failedRules: {}
          });
        }
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  created() {
    this.loading = true;
    Promise.all([
      this.GET_ORGANISATIONS_TYPES(),
      this.GET_ORGANISATIONS_JURIDICTIONS()
    ]).then(() => {
      this.loading = false;
    });
    if (this.currentFormId === 'addOrganisation') {
      this.form = {
        ...this.form,
        ...this.currentForm
      };
    }
  },

  methods: {
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapMutations('organisations', [
      'SET_LAST_CREATED_ORGANISATION'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_TYPES',
      'CREATE_ORGANISATION',
      'SET_ORGANISATION_AGREEMENT',
      'GET_ORGANISATIONS_JURIDICTIONS'
    ]),
    ...mapActions('usergroups', [
      'SET_USERGROUP_THUMBNAIL',
      'GET_USERGROUPS_LIST',
      'GET_USERGROUP_DETAIL',
      'UPDATE_USERGROUP'
    ]),

    setThumbnail(e) {
      this.form.thumbnail = e;
    },

    setAgreement(e) {
      const formData = new FormData();
      formData.append('file', e);
      this.form.agreement = formData;
    },

    addOrgToSphere(e) {
      const index = this.selectedSpheres.findIndex(el => el.id === e.id);
      if (index === -1) {
        this.selectedSpheres.push(e);
      }
      this.sphere = null;
    },

    removeOrgFromSphere(e) {
      const index = this.selectedSpheres.findIndex(el => el.id === e.id);
      this.selectedSpheres.splice(index, 1);
    },

    addOrganisation() {
      this.loading = true;
      const data = {
        additional_id: this.form.additional_id,
        display_name: this.form.display_name,
        codename: slugify(this.form.display_name),
        acronym: this.form.acronym,
        registration_number: this.form.registration_number,
        organisation_type: this.organisationsTypes.find(el => el.display_name === this.form.organisation_type.label),
        is_active: true,
        postal_address: this.form.postal_address,
        description: this.form.description,
        website_url: this.form.website_url,
        phone_number: this.form.phone_number,
        agreement_signed: this.form.agreement_signed,
        jurisdiction: this.territoireCheckbox ? null : this.form.jurisdiction,
        partner: this.form.partner
      };
      this.CREATE_ORGANISATION(data)
        .then(() => {
          if (this.lastCreatedOrganisation) {
            Promise.all([
              this.form.thumbnail ? this.SET_USERGROUP_THUMBNAIL({
                id: this.lastCreatedOrganisation.usergroup.id,
                data: this.form.thumbnail
              }) : Promise.resolve(),
              this.form.agreement ? this.SET_ORGANISATION_AGREEMENT({
                id: this.lastCreatedOrganisation.id,
                data: this.form.agreement
              }) : Promise.resolve(),
              this.selectedSpheres.length > 0 ?
                this.GET_USERGROUP_DETAIL(this.lastCreatedOrganisation.usergroup.id)
                  .then(() => {
                    this.UPDATE_USERGROUP({
                      id: this.currentUsergroup.id,
                      data: {
                        ...this.currentUsergroup,
                        parents: this.selectedSpheres.map((el) => { return el.id; })
                      },
                      usergroupType: 'sphere'
                    });
                  }) : Promise.resolve(),
              this.GET_USERGROUPS_LIST({
                direction: null,
                field: null,
                usergroupTypes: []
              })
            ]).then(() => {
              this.SET_LAST_CREATED_ORGANISATION(null);
              this.SET_CURRENT_FORM({
                id: null,
                form: {}
              });
              this.loading = false;
              this.$router.push({ name: 'Organisations' });
            });
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.form = this.currentForm;
          this.loading = false;
        });
    },

    addOrganisationAndContinue() {
      this.loading = true;
      const data = {
        additional_id: this.form.additional_id,
        display_name: this.form.display_name,
        codename: slugify(this.form.display_name),
        acronym: this.form.acronym,
        registration_number: this.form.registration_number,
        organisation_type: this.organisationsTypes.find(el => el.display_name === this.form.organisation_type.label),
        is_active: true,
        postal_address: this.form.postal_address,
        description: this.form.description,
        website_url: this.form.website_url,
        phone_number: this.form.phone_number,
        agreement_signed: this.form.agreement_signed,
        jurisdiction: this.territoireCheckbox ? null : this.form.jurisdiction,
        partner: this.form.partner
      };
      this.CREATE_ORGANISATION(data)
        .then(() => {
          if (this.lastCreatedOrganisation) {
            Promise.all([
              this.form.thumbnail ? this.SET_USERGROUP_THUMBNAIL({
                id: this.lastCreatedOrganisation.usergroup.id,
                data: this.form.thumbnail
              }) : Promise.resolve(),
              this.form.agreement ? this.SET_ORGANISATION_AGREEMENT({
                id: this.lastCreatedOrganisation.id,
                data: this.form.agreement
              }) : Promise.resolve(),
              this.selectedSpheres.length > 0 ?
                this.GET_USERGROUP_DETAIL(this.lastCreatedOrganisation.usergroup.id)
                  .then(() => {
                    this.UPDATE_USERGROUP({
                      id: this.currentUsergroup.id,
                      data: {
                        ...this.currentUsergroup,
                        parents: this.selectedSpheres.map((el) => { return el.id; })
                      },
                      usergroupType: 'sphere'
                    });
                  }) : Promise.resolve(),
              this.GET_USERGROUPS_LIST({
                direction: null,
                field: null,
                usergroupsTypes: []
              })
            ]).then(() => {
              this.loading = false;
              this.$router.push({ name: 'OrganisationDetail', params: { id: this.lastCreatedOrganisation.id } });
              this.SET_LAST_CREATED_ORGANISATION(null);
            });
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.form = this.currentForm;
          this.loading = false;
        });
    }
  },

};
</script>

<style lang="less" scoped>
.add-orga-form {
  margin-left: max(2em, auto);
  margin-right: max(2em, auto);
}

form {
  max-width: 1000px;
}

h2 {
  color: @blue;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}

#orga-spheres-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0.1em;
  margin: 0.5em 0;
  .orga-sphere {
    display: flex;
    margin: 0.2em 1em 0.2em 0;
    padding: 0.5em;
    border-radius: 10px;
    font-size: 0.9em;
    background-color: @blue;
    color: white;
    .b-icon {
      cursor: pointer;
    }
  }
}
</style>
