import { render, staticRenderFns } from "./AddOrganisation.vue?vue&type=template&id=8fa26f50&scoped=true"
import script from "./AddOrganisation.vue?vue&type=script&lang=js"
export * from "./AddOrganisation.vue?vue&type=script&lang=js"
import style0 from "./AddOrganisation.vue?vue&type=style&index=0&id=8fa26f50&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fa26f50",
  null
  
)

export default component.exports